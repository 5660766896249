import { FilterController } from "components/map/fragments"
import { BaseMapElementFactory } from "../base"
import { StationLabel } from "./_StationLabel"
import { ELayers, IStation } from "@types"

export class StationLabelFactory extends BaseMapElementFactory<IStation, StationLabel> {
  constructor(dataEntityList: IStation[], private filterController: FilterController) {
    super(dataEntityList)
  }

  createElements = (parent: Element, preventCb: (element: Element) => void): void => {
    this.dataEntityList.forEach(this.createStationLabel)
    this.mountElements(parent, preventCb)
    this._filter()
  }

  private createStationLabel = (station: IStation): void => {
    this.elements[station.id] = new StationLabel(station)
  }

  mountElements = (parent: Element, preventCb: (element: Element) => void): void => {
    this.dataEntityList.forEach((item) => {
      this.elements[item.id].mount(parent, preventCb)
    })
  }

  override calcPositions = (overlayProjection: google.maps.MapCanvasProjection): void => {
    this.dataEntityList.forEach((item) => {
      this.elements[item.id].calcPosition(overlayProjection)
    })
  }

  filter = (): void => this._filter()

  private _filter(): void {
    const isVisible = this.filterController.hasPassedFilters(ELayers.STATIONS)
    this.setVisible(isVisible)
  }

  setActive(id: number, isActive: boolean): void {
    const targetDataIndex = this.dataEntityList.findIndex((station) => station.id === id)

    this.dataEntityList[targetDataIndex].isActive = isActive

    this.elements[id].setActive(isActive)
  }

  reset = (newData: IStation[]): void => {
    this.dispose()
    this.isVisible = true

    this.dataEntityList = newData
    this.elements = {}
  }
}

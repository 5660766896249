import { BaseMapElement } from "../base"
import { theme } from "@constants"
import { IStation } from "@types"

export class StationLabel extends BaseMapElement<IStation> {
  constructor(station: IStation) {
    super(station)
    const title = this.createTitle(station.name)
    const description = this.createDescription(station.params)

    this._element.appendChild(title)
    this._element.appendChild(description)
  }

  get id(): string {
    return this.dataEntity.name
  }

  protected createElement(id: string): HTMLDivElement {
    const label = document.createElement("div")
    label.id = id
    label.style.cssText = `border: 1px solid white; width: 48px; height: 32px; background: ${theme.palette.primary.main}; border-radius: 4px`
    label.style.display = "flex"
    label.style.flexDirection = "column"
    label.style.justifyContent = "center"
    label.style.alignItems = "center"
    label.style.position = "absolute"
    label.style.zIndex = "10"
    label.style.userSelect = "none"

    label.style.background = this._dataEntity.isActive ? theme.palette.error.dark : theme.palette.primary.main

    return label
  }

  setActive = (isActive: boolean): void => {
    this._dataEntity.isActive = isActive

    this.element.style.background = this._dataEntity.isActive ? theme.palette.error.dark : theme.palette.primary.main
  }

  private createTitle(titleString: string): HTMLParagraphElement {
    const title = document.createElement("p")
    title.innerHTML = titleString
    title.style.display = "block"
    title.style.margin = "0"
    title.style.color = "white"
    title.style.fontSize = "12px"
    title.style.fontWeight = "600"

    return title
  }

  private createDescription(params: string): HTMLParagraphElement {
    const description = document.createElement("p")
    description.innerHTML = params
    description.style.display = "block"
    description.style.margin = "0"
    description.style.color = "white"
    description.style.fontSize = "10px"

    return description
  }
}
